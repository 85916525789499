<template>
  <div class="container">
    <a-scene physics id="theScene" camera-transform-controls embedded>
      <a-sky :src="xrViewImg" rotation="0 -130 0">
      </a-sky>
      <a-entity
        id="cameraRig"
        position="10 1.6 40"
        rotation="0 180 0"
        wasd-controls="fly: true"
        movement-controls="fly: true"
        look-controls
      >
        <a-entity
          id="lefthand"
          camera-transform-controls-hand="hand: left"
          laser-controls="hand: left"
          thumbstick-goto=""
        ></a-entity>
        <a-entity
          id="righthand"
          camera-transform-controls-hand="hand: right"
          laser-controls="hand: right"
        ></a-entity>
        <a-entity id="camera" camera>
          <a-entity
            position="2 0.3 -1.4"
            rotation="0 -35 0"
            id="nodeDesc"
            geometry="primitive: plane"
            material="color: #131383; transparent: true; opacity: 0.05;"
            text="value: 1-wide\ndefault."
            visible="false"
          ></a-entity>
        </a-entity>
      </a-entity>

      <a-entity
        ref="forceGraph"
        id="forceGraph"
        scale="0.2 0.2 0.2"
        :forcegraph="graphToAframe"
      >
      </a-entity>
    </a-scene>
  </div>
</template>

<script>
import 'aframe';
import 'aframe-extras';
import 'aframe-forcegraph-component';
import { mapActions } from 'vuex';
import '@/plugins/aframe-camera-transform-controls-component';
import xrView from '@/assets/images/xr_view2.jpg';

export default {
  name: 'VisitedGraphVR',

  mounted() {},

  methods: {
    ...mapActions({
      graphVisitedDumpFetch: 'graphVisitedDumpFetch',
    }),
  },

  data() {
    return {
      xrViewImg: xrView,
    };
  },

  created() {
    this.graphVisitedDumpFetch();
  },
  computed: {
    explore_data() {
      return {
        nodes: this.$store.state.gmodule.g6_explore_data.nodes.map((item) => ({
          ...item,
        })),
        links: this.$store.state.gmodule.g6_explore_data.edges.map((item) => ({
          ...item,
        })),
      };
    },
    graphToAframe() {
      const graphFromState = this.explore_data;
      const filteredNodeList = graphFromState.nodes;
      const filteredEdgeList = graphFromState.links;

      const nodeList = JSON.stringify(filteredNodeList).replaceAll(';', '.');
      const edgeList = JSON.stringify(filteredEdgeList).replaceAll(';', '.');

      const forceGraphAttr = {
        nodes: nodeList,
        links: edgeList,
        'node-auto-color-by': 'id',
        'node-label': (node) => node.label, // Add a label property that returns the node's name
        'link-auto-color-by': 'label',
        'link-label': 'label',
        'link-curvature': 0.1,
        'node-opacity': 1,
        'link-width': '1px',
      };

      const forceGraphVal = Object.keys(forceGraphAttr)
        .map((attr) => `${attr}: ${forceGraphAttr[attr]}`)
        .join(';');

      return forceGraphVal;
    },

  },

  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.container {
  height: 80vh;
}
#theScene {
  width: 100%;
  height: 100%;
}
</style>
